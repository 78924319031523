$primary-color:#1887AB;
$secondary-color: #0070ff;
$dark:#5f6978;

$enable-negative-margins: true;

$theme-colors: (
  "primary":$primary-color,
  "secondary": $secondary-color,
  "success": #4CAF50,
  "info": #2196F3,
  "warning": #FFC107,
  "danger": #F44336,
  "light": #F5F5F5,
  "dark": #263238
);

@import 'bootstrap/scss/bootstrap.scss';




