// Login.module.scss
.container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background-color: #f0f2f5;
}

.form {
  width: 100%;
  max-width: 400px;
  padding: 2rem;
  background: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.15);
}

.formGroup {
  margin-bottom: 1.5rem;
}

.formControl {
  border-radius: 5px;
  border: 1px solid #ced4da;
}

.submitButton {
  width: 100%;
  padding: 10px;
  border-radius: 5px;
  font-size: 1rem;


}
.linkButton {
  text-decoration: none;
}

