.container {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #333;
    font-family: 'Arial', sans-serif;
  }
  
    .recording-section {
      text-align: center;
  
      h1 {
        margin-bottom: 20px;
      }
  
      .record-btn {
        font-size: 1.5rem;
        padding: 10px 20px;
        display: flex;
        align-items: center;
        justify-content: center;
  
  
        svg {
          margin-right: 10px;
        }
      }
    }
  